import NavLinks from './NavLinks'

const Navigation = () => {
  return (
    <nav className='navigation'>
      <NavLinks />
    </nav>
  )
}

export default Navigation
